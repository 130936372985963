import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const SearchBox = () => {
    const [keyword, setKeyword] = useState('');
    const [showBackdrop, setShowBackdrop] = useState(false);
    const [showSearchBox, setShowSearchBox] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState('전체');
    const [selectedCategoryChanged, setSelectedCategoryChanged] = useState(false);
    const [showPopupBottom, setShowPopupBottom] = useState(false);
    const [selectedMobileCategory, setSelectedMobileCategory] = useState('전체');
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1200);

    const handleKeyword = event => {
        setKeyword(event.target.value);
        console.log(event.target.value);
    };

    const handleInputFocus = () => {
        if (!isMobile) {
            setShowBackdrop(true);
        }
    };

    const handleBtnSelectClick = () => {
        setShowBackdrop(true);
        if (isMobile) {
            setShowPopupBottom(!showPopupBottom);
        } else {
            setShowSearchBox(!showSearchBox);
        }
    };

    const handlePopupBottomCancel = () => {
        setShowPopupBottom(false);
        setShowBackdrop(false);
    };

    const handlePopupBottomApply = () => {
        setShowPopupBottom(false);
        setShowBackdrop(false);

        if (isMobile && selectedCategoryChanged) {
            setSelectedCategory(selectedMobileCategory);
            setSelectedCategoryChanged(false);
        }
    };

    const handleBackdropClick = () => {
        setShowBackdrop(false);
        setShowSearchBox(false);
        setShowPopupBottom(false);
    };

    const handleCategoryItemClick = (category) => {
        setSelectedCategory(category);
        setSelectedCategoryChanged(true);
        setShowBackdrop(false);
        setShowSearchBox(false);
        setShowPopupBottom(false);
    };

    const handleCategoryItemClickMobile = (category) => {
        setSelectedMobileCategory(category);
        setSelectedCategoryChanged(true);

        if (isMobile) {
            setSelectedCategory(category);
        }
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1200);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (selectedCategoryChanged) {
            setSelectedCategoryChanged(false);
        }
    }, [selectedCategory, selectedCategoryChanged]);

    return (
        <>
            <div className={`search-box ${showSearchBox ? 'on' : ''}`}>
                    <div className="select-box">
                        <button className="btn-select" type="button" onClick={handleBtnSelectClick}>
                            {selectedCategory}
                        </button>
                        <div className="select-menu">
                            <ul>
                                <li className={`item ${selectedCategory === '전체' ? 'on' : ''}`} onClick={() => handleCategoryItemClick('전체')}><span>전체</span></li>
                                <li className={`item ${selectedCategory === '카테고리명1' ? 'on' : ''}`} onClick={() => handleCategoryItemClick('카테고리명1')}><span>카테고리명1</span></li>
                                <li className={`item ${selectedCategory === '카테고리명2' ? 'on' : ''}`} onClick={() => handleCategoryItemClick('카테고리명2')}><span>카테고리명2</span></li>
                                <li className={`item ${selectedCategory === '카테고리명3' ? 'on' : ''}`} onClick={() => handleCategoryItemClick('카테고리명3')}><span>카테고리명3</span></li>
                                <li className={`item ${selectedCategory === '카테고리명4' ? 'on' : ''}`} onClick={() => handleCategoryItemClick('카테고리명4')}><span>카테고리명4</span></li>
                            </ul>
                        </div>
                    </div>
                    <input type="text" className="s-input show-pc" title="search" placeholder="도시나 상품을 검색해 보세요" onFocus={handleInputFocus} value={keyword} onChange={handleKeyword} />
                    <input type="text" className="s-input show-mo" title="search" placeholder="검색해 보세요" onFocus={handleInputFocus} />
                    <Link className="btn-search" to={`/result`} state={{keyword: keyword}}><i className="ico ico-search" /></Link>
                </div>
            <div className={`popup-bottom ${showPopupBottom ? 'show' : ''}`}>
                <div className="popup-select-category">
                    <div className="title">카테고리를 선택해 주세요</div>
                    <div className="select-menu">
                        <ul>
                            <li className={`item ${selectedMobileCategory === '전체' ? 'on' : ''}`} onClick={() => handleCategoryItemClickMobile('전체')}><span>전체</span></li>
                            <li className={`item ${selectedMobileCategory === '카테고리명1' ? 'on' : ''}`} onClick={() => handleCategoryItemClickMobile('카테고리명1')}><span>카테고리명1</span></li>
                            <li className={`item ${selectedMobileCategory === '카테고리명2' ? 'on' : ''}`} onClick={() => handleCategoryItemClickMobile('카테고리명2')}><span>카테고리명2</span></li>
                            <li className={`item ${selectedMobileCategory === '카테고리명3' ? 'on' : ''}`} onClick={() => handleCategoryItemClickMobile('카테고리명3')}><span>카테고리명3</span></li>
                            <li className={`item ${selectedMobileCategory === '카테고리명4' ? 'on' : ''}`} onClick={() => handleCategoryItemClickMobile('카테고리명4')}><span>카테고리명4</span></li>
                            <li className={`item ${selectedMobileCategory === '카테고리명5' ? 'on' : ''}`} onClick={() => handleCategoryItemClickMobile('카테고리명5')}><span>카테고리명5</span></li>
                            <li className={`item ${selectedMobileCategory === '카테고리명6' ? 'on' : ''}`} onClick={() => handleCategoryItemClickMobile('카테고리명6')}><span>카테고리명6</span></li>
                            <li className={`item ${selectedMobileCategory === '카테고리명7' ? 'on' : ''}`} onClick={() => handleCategoryItemClickMobile('카테고리명7')}><span>카테고리명7</span></li>
                        </ul>
                    </div>
                </div>
                <div className="popup-footer">
                    <button className="btn-black btn-grey-border" type="button" onClick={handlePopupBottomCancel}>취소</button>
                    <button className="btn-black" type="button" onClick={handlePopupBottomApply}>적용</button>
                </div>
            </div>
            <div className={`backdrop ${showBackdrop ? 'show' : ''}`} onClick={handleBackdropClick} />
        </>
    );
};

export default SearchBox;
