
export const targetUrl = "https://dev.bd-bid.com/api"
export const flaskUrl = "https://dev.bd-bid.com/api-flask"
//export const flaskUrl = "http://127.0.0.1:5002/api-flask"

export function number2hangeul(number) {
    let money = number
    let hangeul = ''
    if(money>100000000){
        hangeul += Math.floor(money/100000000)+'억 '
        money = money%100000000
    }
    {/*if (money>10000000){
        console.log(money/10000000)
        hangeul += Math.floor(money/10000000)+'천'
        console.log(hangeul)
        money = money%10000000
        console.log(money)
    }
    if (money>1000000){
        console.log(money/1000000)
        hangeul += Math.floor(money/1000000)+'백'
        console.log(hangeul)
        money = money%1000000
        console.log(money)
    }
    if (money>100000){
        console.log(money/100000)
        hangeul += Math.floor(money/100000)+'십'
        console.log(hangeul)
        money = money%100000
        console.log(money)
    }*/}
    if (money>10000){
        hangeul += Math.floor(money/10000)+'만'
        money = money%10000
    }
    else if(number>10000){
        hangeul+='만'
    }
    if (money>1){
        hangeul += ' '+Math.floor(money/1)+'원'
        money = money%1
    }else{
        hangeul+='원'
    }
    console.log(hangeul)
    return hangeul
}