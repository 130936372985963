import React, { useState, useCallback, useEffect } from 'react';
import Header from '../Components/Header';
import Modal from 'react-bootstrap/Modal';
import MobileDetailMenu from "../Components/MobileDetailMenu";
import {Link} from "react-router-dom";
import { targetUrl } from "../config.js"

const CompleteNiceAPI = () => {
//    window.open('http://localhost:3000/complete-nice-api', '_self').close();
//    console.log(window.opener)
//    console.log(window.opener.document.show1)
//    window.opener.document.getElementById("authPhone").innerText = "휴대폰 본인인증 완료";
//    window.opener.document.getElementById("name").value = "김효정";
//    window.opener.document.getElementById("birth").value = "970905";
//    window.opener.testPopup()
//    console.log(window.opener.document)
//    console.log(window.opener.document.getElementById("auth"))
    console.log(window.location.href)

    const [parsed, setParsed] = useState(null);
    const [show, setShow] = useState(true);
    const [token, setToken] = useState(null);
    const [enc, setEnc] = useState(null);
    const [integrity, setIntegrity] = useState(null);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleLoad = useCallback(() => {
//        window.close();
    }, []);

    async function deliverServer(url) {
        const queryString = require('querystring-es3');
        const parsed = queryString.parse(url);
        console.log(parsed)
        setParsed(parsed)
        console.log({
                "enc_data": encodeURIComponent(parsed.enc_data),
                "integrity_value": encodeURIComponent(parsed.integrity_value),
                "token_version_id": encodeURIComponent(parsed.token_version_id)
                })
        const response = await fetch(targetUrl + "/self-certification/success-handler", {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(
                {
                "enc_data": encodeURIComponent(parsed.enc_data),
                "integrity_value": encodeURIComponent(parsed.integrity_value),
                "token_version_id": encodeURIComponent(parsed.token_version_id)
                }
            )
        })
        console.log(response)
        console.log(response.json())

        console.log(url)
        if (response.status == 200) {
            console.log(response)
            const queryString = require('querystring-es3');
            const parsed = queryString.parse(url);
            console.log(parsed)
            window.opener.postMessage({
                params: {
                    "enc_data": encodeURIComponent(parsed.enc_data),
                    "integrity_value": encodeURIComponent(parsed.integrity_value),
                    "token_version_id": encodeURIComponent(parsed.token_version_id)
                }});

//            window.close();
            return 'success'
        }
        else {
            return 'error'
        }
    }

    useEffect(() => {
//        window.close();
        {/*window.opener.document.getElementById("auth").value = "휴대폰 인증 완료"*/}
        if (window.location.href.includes('?')){
            let url = window.location.href.split('?')[1]
            url.split('&')[0].split('=')
            const queryString = require('querystring-es3');
            const parsed = queryString.parse(url);
            console.log(parsed)
            console.log("window.ReactNativeWebView")
            console.log(window.ReactNativeWebView)
            if (window.ReactNativeWebView){
                window.ReactNativeWebView?.postMessage(JSON.stringify({
                params: {
                    "enc_data": encodeURIComponent(parsed.enc_data),
                    "integrity_value": encodeURIComponent(parsed.integrity_value),
                    "token_version_id": encodeURIComponent(parsed.token_version_id)
                }}));
                console.log("window.ReactNativeWebView-inside")
            }
            else{
                const returnValue = deliverServer(url)
                console.log(returnValue)
                window.opener.postMessage({ params: {
                    "enc_data": encodeURIComponent(parsed.enc_data),
                    "integrity_value": encodeURIComponent(parsed.integrity_value),
                    "token_version_id": encodeURIComponent(parsed.token_version_id)
                }});
                if (returnValue=='success'){
                    window.close();
                }
            }
            {/*window.opener.document.getElementById("token_version_id_complete").value = parsed.token_version_id
            window.opener.document.getElementById("enc_data_complete").value = parsed.enc_data
            window.opener.document.getElementById("integrity_value_complete").value = parsed.integrity_value
            window.opener.document.getElementById("name").value = parsed.integrity_value
            window.opener.document.getElementById("birth").value = parsed.integrity_value*/}
        }
        handleLoad();
    }, []);


    return (
        <>
            <Header/>
            <MobileDetailMenu />
            <Modal className="modal-green" show={show} onHide={handleClose} centered animation={false} backdrop="static">
                <button className="btn-modal-close" onClick={handleClose}><i className="ico ico-modal-close-white" /></button>
                <div className="content">
                    <div className="title">회원 가입을 환영 합니다!</div>
                    <div className="desc">회원 가입 완료</div>
                    <div className="sign-in-completed">
                        <i className="ico ico-completed"/>
                        <div className="txt">{parsed==null? parsed: ''}</div>
                    </div>
                    <div className="btn-wrap">
                        <Link className="link" to='/'><button className="btn btn-black btn-lg" onClick={handleClose}>확인</button></Link>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default CompleteNiceAPI;
